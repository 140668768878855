<template>
  <el-dialog
    v-if="allocationDialog.show"
    :title="allocationDialog.title"
    :visible.sync="allocationDialog.show"
    center
    width="50%"
    top="40vh"
  >
    <div class="cont">
      <el-row>
        <el-col :span="6"><div class="l-title">选择学员：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">
            <el-select
              v-model="phoneArr"
              multiple
              collapse-tags
              style="width:350px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.phone"
                :label="item.name"
                :value="item.phone"
              >
              </el-option>
            </el-select></div
        ></el-col>
      </el-row>
      <div class="btn-wrap">
        <el-button @click="handleSubmit" type="primary">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getEnterpriseStaff, postAllocation } from "@/api/entCenter";
export default {
  name: "OrderDetailDialog",
  props: {
    allocationDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      info: {}, // 订单信息
      options: [
      ],
      phoneArr: [],
    };
  },
  mounted() {
    console.log("ccc...");
    this.getEnterpriseStaff()
  },
  methods: {
    //员工管理-分页列表接口
    getEnterpriseStaff() {
      const obj ={
        pageSize: 1000
      }
      getEnterpriseStaff(obj).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const { data } = res.data;
            this.options = data.rows;
            console.log(this.options);
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
          console.log(this.info);
        }
      });
    },
    handleSubmit() {
      // console.log('aaa', this.phoneArr);
      if(this.phoneArr.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择要分配的学员'
        })
      }else{
        const obj={
          phones: this.phoneArr,
          orderId: this.allocationDialog.orderId
        }
        postAllocation(obj).then(res=>{
          console.log(res);
          if(res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '分配成功！',
              onClose: ()=>{
                this.allocationDialog.show = false;
                this.$emit('getLists')
              }
            })
          }else{
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
      })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  margin: 0px auto 0;
  .l-title {
    margin: 10px 0;
    text-align: right;
    color: #666;
  }
  .r-cont {
    color: #333;
    text-align: left;
  }
  .red {
    color: red;
    font-weight: bold;
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: center;
  }
}
</style>