<template>
  <div class="class-management">
    <div v-if="type=== 1" style="text-align: left;"><Head title="课程分配"></Head></div>
    <div v-if="type=== 3" style="text-align: left;"><Head title="分配列表"></Head></div>
    <div v-if="type === 1" class="cont">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#f5f5f5' }"
      >
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="productName" label="课程名称"> </el-table-column>
        <el-table-column label="课程照片" width="146">
          <template slot-scope="scope">
            <img :src="scope.row.productImg" class="photo" />
          </template>
        </el-table-column>
<!--        <el-table-column prop="productType" label="课程类型" width="85"> -->
<!--            <template slot-scope="scope">-->
<!--              <el-tag v-if="scope.row.productType===0">培训课</el-tag>-->
<!--              <el-tag v-if="scope.row.productType===1">公开课</el-tag>-->
<!--              <el-tag v-if="scope.row.productType===2">直播课</el-tag>-->
<!--              <el-tag v-if="scope.row.productType===3">考试课</el-tag>-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="quantity" label="可分配(人)" width="90">
        </el-table-column>
        <el-table-column label="已分配(人)" width="90">
          <template slot-scope="scope">
            <p>{{ scope.row.quantity - scope.row.leftNum }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="leftNum" label="剩余分配(人)" width="120">
        </el-table-column>
        <!-- <el-table-column label="订单金额">
          <template slot-scope="scope">
            <p class="red">¥ {{ scope.row.orderPrice }}</p>
          </template>
        </el-table-column>
        <el-table-column label="支付状态">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.statusName }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="110">
          <template slot-scope="scope">
            <div v-if="scope.row.quantity > 0" class="btn-small">
              <el-button
                type="danger"
                size="mini"
                @click="handleAllocation(scope.row)"
                >分配
              </el-button>
            </div>
            <div v-if="scope.row.quantity !== undefined" class="btn-small">
              <el-button
                type="primary"
                size="mini"
                @click="viewAllocation(scope.row)"
                >查看分配
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <Pagination
          :total="search.total"
          :page.sync="search.page"
          :limit.sync="search.size"
          @pagination="getLists"
        />
      </div>
    </div>
    <!-- 去分配 -->
    <div v-if="type === 2" class="cont">
      <div class="btn-wrap">
        <el-button
          type="primary"
          size="small"
          @click="addCertificate"
          v-if="isShow2"
          >返回</el-button
        >
      </div>
    </div>
    <!-- 查看分配 -->
    <div v-if="type === 3" class="cont">
      <div class="btn-wrap">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </div>
      <div class="cont">
        <el-table
          :data="allocationData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#f5f5f5' }"
        >
          <el-table-column type="index" width="50" label="序号" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="phone" label="手机号码" />
          <el-table-column prop="idCard" label="身份证号码" />
          <el-table-column prop="email" label="邮箱" />
        </el-table>
        <div class="pagination">
        <Pagination
          :total="search2.total"
          :page.sync="search2.page"
          :limit.sync="search2.size"
          @pagination="getLists"
        />
      </div>
      </div>
    </div>
    <!-- 分配课程弹窗 -->
    <AllocationDialog
      v-if="allocationDialog.show"
      :allocation-dialog="allocationDialog"
      @getLists="getLists"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getEntOrderList, getAllocation } from "@/api/entCenter";
import AllocationDialog from './components/AllocationDialog.vue'
import Head from './Head'
export default {
  name: "ClassManagement",
  components: {
    Pagination,
    AllocationDialog,
    Head
  },
  data() {
    return {
      type: 1, // 1 展示列表； 2展示分配，3查看分配
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      search2: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      tableData: [],
      allocationData: [], //已分配数据
      allocationDialog: {}
    };
  },
  mounted() {
    this.getLists();
  },
  methods: {
    //课程分配管理列表
    getLists() {
      getEntOrderList(
        Object.assign(this.search, {
          //1 订单列表 （默认） 2 企业用户，我的待分配课程订单
          queryType: 2,
        })
      ).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.search.total = res.data.data.total;
          this.tableData = res.data.data.rows;
        }
      });
    },
    // 查看已经分配列表
    getAllocation(orderId) {
      getAllocation(Object.assign(this.search, {orderId: orderId})).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.search2.total = res.data.data.total;
          this.allocationData = res.data.data.rows;
          console.log(this.allocationData);
        }
      });
    },
    // 去分配
    handleAllocation(row) {
      console.log(row);
      // this.type = 2;
      
      this.allocationDialog.show = true;
      this.allocationDialog = {
        show: true,
        title: "分配课程",
        orderId: row.orderId,
      };
    },
    // 查看分配
    viewAllocation(row) {
      console.log(row);
      this.type = 3;
      this.getAllocation(row.orderId);
    },
    // 返回
    goBack() {
      this.type = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.class-management {
  em {
    display: flex;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .photo {
    width: 120px;
    height: 80px;
  }
  .red {
    color: red;
  }
  .pagination {
    margin-top: 30px;
  }
  .operate {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .btn-small {
    margin: 5px 0;
  }
  .btn-wrap {
    margin: 0 0 10px 0;
    text-align: right;
  }
}
</style>