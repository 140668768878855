// 企业中心相关api
import request from '../utils/request'
// import qs from 'qs'

//0010083-员工管理-分页列表接口
export function getEnterpriseStaff(params={}) {
  return request({
      method: 'GET',
      url:'enterprise_staff',
      params
  })
}

//0010084-员工管理-单个新增或者更新接口
export function postEnterpriseStaff(data) {
  return request({
      method: 'POST',
      url: 'enterprise_staff',
      data
  })
}

// 0010087-员工管理-批量删除接口
export function enterpriseStaffDelete(data) {
  return request({
      method: 'POST',
      url: 'enterprise_staff/delete',
      data
  })
}

// 0010022-我的订单列表分页接口
export function getEntOrderList(params={}) {
  return request({
      method: 'GET',
      url:'order',
      params
  })
}

//0010034-订单课程分配-已分配列表接口
export function getAllocation(params={}) {
  return request({
      method: 'GET',
      url:'order/course/allocation',
      params
  })
}

// 0010035-订单课程分配-分配接口
export function postAllocation(data) {
  return request({
      method: 'POST',
      url: 'order/course/allocation',
      data
  })
}

export function editStaff(data) {
  return request({
      method: 'POST',
      url: 'enterprise_staff/edit',
      data
  })
}

